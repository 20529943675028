import React from 'react';
import './App.css';
import Logo from './Logo'; // Import the Logo component
import Logo2 from './Logo2'; // Import the Logo component
import NavBar from './NavBar'
import ConcertCalendar from './ConcertCalendar'; // Import the ConcertCalendar component

function App() {
  return (
    <div className="App">
      <NavBar />
      <h2 className="headline">Gotham Sonic</h2>
      <header className="App-header">
      <div className="logo-container">
        <Logo />
      </div>
      <Logo2 />      
      </header>
      <ConcertCalendar />
    </div>
  );
}

export default App;

