import React from 'react';
import gothamSonicPic from './GothamSonicPic4.png'; // Import the image

const Logo = () => {
  // This function checks if the device is likely a mobile device
  const isMobileDevice = () => {
    return (/(android|iphone|ipad|ipod|blackberry|mini|windows\sce|palm)/i.test(navigator.userAgent.toLowerCase()));
  }

  // Return a div with a background image for mobile devices, and an img tag for desktop
  if (isMobileDevice()) {
    return <div className="logo-background" alt="GothamSonic Logo"></div>;
    
  } else {

    // Use img tag for non-mobile devices for the cool zoom effect
    return <img src={gothamSonicPic} alt="GothamSonic Logo" style={{ width: '100%', height: 'auto' }} /> 
  }
};

export default Logo;
