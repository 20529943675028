import React, { useState } from "react";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false); // State to manage the toggle of the menu

  const isMobileDevice = () => {
    return (/(android|iphone|ipad|ipod|blackberry|mini|windows\sce|palm)/i.test(navigator.userAgent.toLowerCase()));
  };

  const showAlert = () => {
    alert("Coming soon! But no need to log in to view the concert calendar!");
  };

  const forumsAlert = () => {
    alert("Coming Soon! Make Comments and/or Post your original songs in one of our Forums classified by genre");
  };

  const echoAlert = () => {
    alert("Coming Soon! HELLLO???? HELLLLLOO!!! HELLLLOOOOOO???? All songs from any genre can end up here can end up here can end up here");
  };

  const songAlert = () => {
    alert("Coming Soon! upload songs/album to your artist page, the echo chamber, or in one of our forums -- or in all 3 places!");
  };

  return (
    <div className="navfirstparent">
      <nav style={{ backgroundColor: "#333", color: "black", size: "20px" }}>
        {isMobileDevice() && (
          <>
            <button className="nav-button hamburger" onClick={() => setIsOpen(!isOpen)}>
              ☰
            </button>
            {isOpen && (
              <div className="menu-content">
                <button id="loginBtn" className="nav-button" onClick={showAlert}>Log in</button>
                <button id="profileBtn" className="nav-button" onClick={forumsAlert}>Your Artist Profile</button>
                <button id="uploadBtn" className="nav-button" onClick={songAlert}>Upload Songs</button>
                <button id="echoBtn" className="nav-button" onClick={echoAlert}>Echo Chambers</button>
                <button id="registerBtn" className="nav-button" onClick={showAlert}>Register (free)</button>
              </div>
            )}
          </>
        )}
        {!isMobileDevice() && (
          <>
            <div className="nav-button hamburger"> </div>
              <div className="menu-content">
                <button id="loginBtn" className="nav-button" onClick={showAlert}>Log in</button>
                <button id="profileBtn" className="nav-button" onClick={forumsAlert}>Your Artist Profile</button>
                <button id="uploadBtn" className="nav-button" onClick={songAlert}>Upload Songs</button>
                <button id="echoBtn" className="nav-button" onClick={echoAlert}>Echo Chambers</button>
                <button id="registerBtn" className="nav-button" onClick={showAlert}>Register (free)</button>
              </div>
             <div class="horizontal-line"></div>     
          </>
        )}
      </nav>
    </div>
  );
};

export default NavBar;

